import imageSection1 from '../../assets/home_section_1.png';
import createPlan1 from '../../assets/create_plan_1.png';
import createPlan2 from '../../assets/create_plan_2.png';
import createPlan3 from '../../assets/create_plan_3.png';
import chat from '../../assets/chat.png';
import gallery from '../../assets/gallery.png';
import bills from '../../assets/bills.png';
import statisticsGroup from '../../assets/statistics_group.png';
import statisticsUser from '../../assets/statistics_user.png';
import './home.css';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import Buttons from '../buttons/Buttons';

function Home() {
    return (
        <>
            <div className="container">
                <Header />
                <div className="container_body">
                    <Section1 />
                    <Section2 />
                    <Section3 />
                    <Section4 />
                </div>
                <Footer />
            </div>
        </>
    );
}

function Section1() {
    return (
        <>
            <div className='container-section-1 pt-20'>
                <Buttons />
                <div className='container-row'>
                    <div className='container-40 ml-60'>
                        <h2>Organiza planes con amigos, fácil y rápido</h2>
                        <div className='mt-20'>
                            <span className='color-red bold pr-5'>Integración Todo-en-Uno:</span>
                            <span>Organización, comunicación, gastos, fotos y estadísticas en una sola plataforma.</span>
                        </div>
                        <div className='mt-20'>
                            <span className='color-red bold pr-5'>Optimización del Tiempo:</span>
                            <span>Encuestas, recordatorios y confirmaciones automáticas que eliminan el trabajo manual.</span>
                        </div>
                        <div className='mt-20'>
                            <span className='color-red bold pr-5'>Datos y Estadísticas:</span>
                            <span>Valor emocional y práctico (seguimiento de interacciones y actividades).</span>
                        </div>
                    </div>
                    <div className='container-60 center'>
                        <img src={imageSection1} alt="section1" className='container-image-step-1'/>
                    </div>
                </div>
            </div>
        </>
    );
}

function Section2() {
    return (
        <div className='container-section-2 pb-20'>
            <div className='container-row center'>
                <h2>Crea planes en dos sencillos pasos y evita problemas de organización</h2>
            </div>
            <div className='container-row'>
                <div className='center space-around ml-30 container-60'>
                    <img src={createPlan1} alt="createPlan1" className='container-image-step-2' />
                    <img src={createPlan2} alt="createPlan2" className='container-image-step-2' />
                    <img src={createPlan3} alt="createPlan3" className='container-image-step-2' />
                </div>
                <div className='container-40 mr-20'>
                    <ul>
                        <li>
                            <div className='mt-20'>
                                <span className='bold pr-5'>Consulta la disponibilidad:</span>
                                <span>Comprueba si tus amigos ya tienen otros planes para el día que quieres elegir.</span>
                            </div>
                        </li>
                        <li>
                            <div className='mt-20'>
                                <span className='bold pr-5'>Plazo para confirmar:</span>
                                <span>Configura una fecha máxima para que te confirmen el plan y así puedas saber quien asiste y quien no.</span>
                            </div>
                        </li>
                        <li>
                            <div className='mt-20'>
                                <span className='bold pr-5'>Encuestas:</span>
                                <span>Si no tienes claras la fecha, haz una encuesta y la opción ganadora será la fecha del plan.</span>
                            </div>
                        </li>
                        <li>
                            <div className='mt-20'>
                                <span className='bold pr-5'>Recordatorios:</span>
                                <span>Configura notificaciones automáticas a los que han confirmado el plan para que no se les olvide.</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

function Section3() {
    return (
        <div className='container-section-3 pb-20'>
            <div className='container-row center'>
                <h2>Cada plan tiene asociado un espacio exclusivo de gastos, chat y galería</h2>
            </div>
            <div className='container-row'>
                <div className='container-40 ml-30'>
                    <ul>
                        <li>
                            <div className='mt-20'>
                                <span className='bold pr-5'>Consulta la disponibilidad:</span>
                                <span>Comprueba si tus amigos ya tienen otros planes para el día que quieres elegir.</span>
                            </div>
                        </li>
                        <li>
                            <div className='mt-20'>
                                <span className='bold pr-5'>Plazo para confirmar:</span>
                                <span>Configura una fecha máxima para que teconfirmen el plan y así puedas saber quien asiste y quien no.</span>
                            </div>
                        </li>
                        <li>
                            <div className='mt-20'>
                                <span className='bold pr-5'>Encuestas:</span>
                                <span>Si no tienes claras la fecha, haz una encuesta y la opción ganadora será la fecha del plan.</span>
                            </div>
                        </li>
                        <li>
                            <div className='mt-20'>
                                <span className='bold pr-5'>Recordatorios:</span>
                                <span>Configura notificaciones automáticas a los que han confirmado el plan para que no se les olvide.</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='center space-around container-60'>
                    <img src={chat} alt="chat" className='container-image-step-3' />
                    <img src={bills} alt="bills" className='container-image-step-3' />
                    <img src={gallery} alt="gallery" className='container-image-step-3' />
                </div>

            </div>
        </div>
    );
}

function Section4() {
    return (
        <div className='container-section-4 pb-20'>
            <div className='container-row center'>
                <h2>Consulta estadísticas de tu actividad social en tu grupo de amigos</h2>
            </div>
            <div className='container-row'>
                <div className='center space-around ml-30 container-60'>
                    <img src={statisticsUser} alt="statisticsUser" className='container-image-step-4' />
                    <img src={statisticsGroup} alt="statisticsGroup" className='container-image-step-4' />
                </div>
                <div className='container-40 ml-30 mr-40'>
                    <ul>
                        <li>
                            <div className='mt-20'>
                                <span className='bold pr-5'>Estadísticas individuales:</span>
                                <span>Revisa cuántos planes has propuesto, a cuántos has asistido, el grupo de amigos con el que más planes haces, o la persona con la que más coincides.</span>
                            </div>
                        </li>
                        <li>
                            <div className='mt-20'>
                                <span className='bold pr-5'>Estadísticas grupales:</span>
                                <span>Consulta en cada uno de tus grupo cuántos planes habéis hecho, quién es la persona que más planes propone, la que más asiste o vuestro gasto total acumulado.</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}


export default Home;
