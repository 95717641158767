import Header from '../header/Header';
import Footer from '../footer/Footer';
import { useNavigate } from 'react-router-dom';
import './legaldocuments.css';

function LegalDocuments() {

    const navigate = useNavigate();

    return (
        <div className='container'>
            <Header />
            <div className="container-body center">
                <div className="container-legal center mt-10" onClick={() => navigate("/privacy")}>
                    <span className='legal-text'>Política de privacidad</span>
                </div>
                <div className="container-legal center mt-10" onClick={() => navigate("/terms-use")}>
                    <span className='legal-text'>Términos y condiciones</span>
                </div>
                <div className="mt-10" />
            </div>
            <Footer />
        </div>
    );
}

export default LegalDocuments;
