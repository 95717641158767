import './header.css';
import logo from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';

function Header() {

    const navigate = useNavigate();

    return (
        <div className='container-header center' onClick={() => navigate("/home")}>
            <img src={logo} alt="Logo" className='container-image pointer' />
        </div>
    );
}

export default Header;
