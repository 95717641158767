import { useNavigate } from "react-router-dom";
import { APP_NAME } from "../..";
import Buttons from "../buttons/Buttons";
import Header from "../header/Header";

function Splash() {

    const navigate = useNavigate();

    return (
        <div className="container">
            <Header />
            <div className="container-full-body">
                <div>
                    <p className="label-title bold center">Obtén la aplicación de {APP_NAME}:</p>
                </div>
                <Buttons />
                <div>
                    <span className='center font-size pointer bold' onClick={() => navigate("/home")}>O continuá al sitio web de {APP_NAME}</span>
                </div>
            </div>
        </div>
    );
}

export default Splash;
