import { useNavigate } from 'react-router-dom';
import instagram from '../../assets/instagram.png';
import facebook from '../../assets/facebook.png';
import youtube from '../../assets/youtube.png';
import './footer.css';

const INSTAGRAM_URL = "https://www.instagram.com/plannits.es?igsh=ZzhyaTZ3NmNlNnps&utm_source=qr";
const FACEBOOK_URL = "https://youtu.be/4uSa26xsUZ4?si=V_F7JrsXbSo2KDIp";
const YOUTUBE_URL = "https://youtu.be/4uSa26xsUZ4?si=V_F7JrsXbSo2KDIp";

function Footer() {

    const navigate = useNavigate();

    return (
        <div className='container-footer pt-20'>
            <div className='container-row-2 center gap-60'>
                <div className='pointer'>
                    <a href={INSTAGRAM_URL} target='_blank' rel="noreferrer">
                        <img src={instagram} alt="instagram" className='image-footer' />
                    </a>
                </div>
                <div className='pointer'>
                    <a href={FACEBOOK_URL} target='_blank' rel="noreferrer">
                        <img src={facebook} alt="facebook" className='image-footer' />
                    </a>
                </div>
                <div className='pointer'>
                    <a href={YOUTUBE_URL} target='_blank' rel="noreferrer">
                        <img src={youtube} alt="youtube" className='image-footer' />
                    </a>
                </div>
            </div>
            <div className='container-row-2 center gap-60 mt-30 pb-20'>
                <span className='container-label-title-footer pointer bold' onClick={() => navigate("/support")}>Soporte</span>
                <span className='container-label-title-footer pointer bold' onClick={() => navigate("/privacy")}>Política de privacidad</span>
                <span className='container-label-title-footer pointer bold' onClick={() => navigate("/terms-use")}>Términos y condiciones</span>
            </div>
        </div>
    );
}

export default Footer;
