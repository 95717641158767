import Header from '../header/Header';
import Footer from '../footer/Footer';
import { APP_NAME, EMAIL } from '../..';

function Privacy() {

    return (
        <div className='container'>
            <Header />
            <div className='pl-10 pr-10'>
                <h1>Política de Privacidad</h1>
                <div className='mt-10 bold'>Fecha de última actualización: <span className='pr-5'>Noviembre 2024</span></div>
                <div>
                    <h2>1. Información General</h2>
                    <div className='bold'>Responsable del Tratamiento de Datos:</div>
                    <ul>
                        <li><span className='bold pr-5'>Nombre de la empresa:</span>{APP_NAME}</li>
                        <li><span className='bold pr-5'>Dirección:</span>Avenida de Madrid 3, 28822, Coslada, Madrid</li>
                        <li><span className='bold pr-5'>Correo electrónico de contacto:</span>{EMAIL}</li>
                        <li><span className='bold pr-5'>Número de teléfono de contacto:</span> +34650847054</li>
                    </ul>
                    <div>
                        Propósito: Esta política de privacidad explica cómo recopilamos, utilizamos y protegemos los datos personales de los usuarios de la aplicación móvil
                        <span className='bold pl-5 pr-5'>{APP_NAME}.</span>
                        La seguridad y privacidad de tus datos son fundamentales para nosotros, y esta política cumple con la normativa vigente en España
                        y la Unión Europea, incluyendo el Reglamento General de Protección de Datos (RGPD).
                    </div>
                </div>
                <div>
                    <h2>2. Datos personales que recopilamos</h2>
                    <div>Recopilamos y tratamos los siguientes datos personales de los usuarios en función de las funcionalidades de la aplicación:</div>
                    <ul>
                        <li>
                            <div><span className='bold pr-5'>Datos de registro:</span> Nombre, apellidos, dirección de correo electrónico y contraseña para el registro y uso de la aplicación</div>
                            <div className='mt-10'>Cuentas de Google: como parte del uso, es posible que se le presente la opción de vincular su cuenta de Google.
                                Si autoriza a {APP_NAME} a utilizar su cuenta de Google para iniciar sesión, su nombre y dirección de correo electrónico pueden compartirse con {APP_NAME}. No es necesario compartir estos datos para abrir una cuenta.
                                {APP_NAME} almacenará estos datos y, por lo general, se tratarán como si los hubiera proporcionado manualmente.</div>
                        </li>
                        <li className='mt-10'>
                            <div><span className='bold pr-5'>Contenido generado por el usuario:</span>Información que proporciona al usar nuestro servicio para proponer y gestionar planes. Esto incluye nombre, descripción, fecha y ubicación de los planes, así como mensajes, gastos y galería de material multimedia que se registre asociado a un plan como parte del uso de la aplicación. </div>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2>3. Finalidades del Tratamiento de los Datos</h2>
                    <div>Los datos personales se tratan para las siguientes finalidades:</div>
                    <ol>
                        <li>
                            <div><span className='bold '>Gestión de grupos y planes:</span>Para permitir que los usuarios propongan planes a sus contactos dentro de los grupos, con detalles de ubicación, fecha y descripción del plan.</div>
                        </li>
                        <li>
                            <div><span className='bold pr-5'>Comunicación:</span>Para que los usuarios puedan enviar y recibir mensajes de texto en tiempo real dentro de los grupos creados en la aplicación.</div>
                        </li>
                        <li>
                            <div><span className='bold pr-5'>Registro de gastos y cálculo de saldos:</span>Para que los usuarios registren, compartan y calculen saldos de gastos entre los miembros del grupo, facilitando la transparencia en las cuentas compartidas.</div>
                        </li>
                        <li>
                            <div><span className='bold pr-5'>Almacenamiento y uso de contenido multimedia:</span>Para permitir que los usuarios suban, compartan y vean fotos y videos en la galería de los grupos, dentro del ámbito privado del grupo.</div>
                        </li>
                        <li>
                            <div><span className='bold pr-5'>Soporte y atención al cliente:</span>Para ofrecer asistencia técnica y resolver incidencias que puedan surgir en el uso de la aplicación.</div>
                        </li>
                        <li>
                            <div><span className='bold pr-5'>Seguridad de la aplicación:</span>Para prevenir usos no autorizados y detectar posibles fraudes o abusos de la plataforma.</div>
                        </li>
                    </ol>
                </div>
                <div>
                    <h2>4. Base Legal para el Tratamiento de los Datos</h2>
                    <div>La recopilación y uso de los datos personales se basa en las siguientes bases legales:</div>
                    <ul>
                        <li>
                            <div><span className='bold pr-5'>Ejecución de un contrato:</span>El tratamiento de datos es necesario para el uso de las funcionalidades de la aplicación, en los términos acordados con el usuario.</div>
                        </li>
                        <li className='mt-10'>
                            <div><span className='bold '>Consentimiento:</span>Recabamos el consentimiento explícito del usuario para acceder a la ubicación del dispositivo y para el almacenamiento y uso de contenido multimedia.
                                Interés legítimo: Tratamos algunos datos para mejorar la seguridad de la aplicación</div>
                        </li>
                        <li className='mt-10'>
                            <div><span className='bold pr-5'>Interés legítimo:</span>Tratamos algunos datos para mejorar la seguridad de la aplicación y personalizar la experiencia del usuario.</div>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2>5. Conservación de los Datos</h2>
                    <div>Los datos personales se conservan mientras el usuario mantenga activa su cuenta en la aplicación y durante el tiempo necesario para cumplir las finalidades de esta política. Una vez que ya no sean necesarios, los datos serán eliminados o anonimizados de acuerdo con la normativa vigente.</div>
                </div>
                <div>
                    <h2>6. Derechos del Usuario</h2>
                    <ul>
                        <li>
                            <div><span className='bold pr-5'>Acceso:</span>Solicitar información sobre sus datos personales tratados en la aplicación.</div>
                        </li>
                        <li>
                            <div><span className='bold pr-5'>Rectificación:</span>Solicitar la corrección de datos inexactos o incompletos.Supresión: Pedir la eliminación de sus datos cuando ya no sean necesarios para los fines establecidos.n</div>
                        </li>
                        <li>
                            <div><span className='bold pr-5'>Supresión:</span>Pedir la eliminación de sus datos cuando ya no sean necesarios para los fines establecidos.</div>
                        </li>
                        <li>
                            <div><span className='bold pr-5'>Limitación:</span>Solicitar la restricción del tratamiento de datos en determinadas circunstancias.</div>
                        </li>
                        <li>
                            <div><span className='bold pr-5'>Portabilidad:</span>Solicitar que los datos sean transferidos a otro proveedor en un formato estructurado y de uso común.</div>
                        </li>
                        <li>
                            <div><span className='bold pr-5'>Oposición:</span>Oponerse al tratamiento de sus datos, especialmente cuando se usen para marketing o publicidad.</div>
                        </li>
                    </ul>
                    <div>Para ejercer estos derechos, contacta con nosotros a través de {EMAIL}. También tienes derecho a presentar una reclamación ante la Agencia Española de Protección de Datos (AEPD) si consideras que se han vulnerado tus derechos.</div>
                </div>
                <div>
                    <h2>7. Compartición de datos con terceros</h2>
                    <div>Para ofrecerle el mejor servicio posible, compartimos ciertos datos personales con proveedores externos que nos prestan servicios esenciales, tales como análisis de datos, marketing, pruebas de rendimiento, y soporte técnico. Estos proveedores acceden únicamente a la información necesaria para cumplir con los servicios contratados y se encuentran sujetos a estrictos términos de confidencialidad y a las medidas de seguridad establecidas por la normativa de protección de datos.</div>
                    <div>También podrían compartirse cuando la divulgación sea necesaria por:</div>
                    <ul>
                        <li>Requirimiento legal.</li>
                        <li>Requerimiento de organismos policiales, entidades reguladoras, autoridades gubernamentales, o tribunales.</li>
                        <li>Con motivo de proteger o defender nuestros derechos legales.</li>
                        <li>En relación con una propuesta de recaudación de capital, compra, fusión o adquisición de una parte de nuestro negocio.</li>
                    </ul>
                    <div>En caso de que los datos se transfieran fuera del Espacio Económico Europeo, nos aseguraremos de que el país de destino ofrece un nivel adecuado de protección de datos o que se establecen garantías adecuadas para proteger la información personal.</div>
                </div>
                <div>
                    <h2>8. Seguridad de los Datos</h2>
                    <div>Implementamos medidas técnicas y organizativas para proteger los datos personales contra accesos no autorizados, pérdida o destrucción. </div>
                </div>
                <div>
                    <h2>9. Cambios en la Política de Privacidad</h2>
                    <div>Esta política puede actualizarse para reflejar cambios en la normativa o en las funcionalidades de la aplicación. Notificaremos a los usuarios a través de la aplicación cualquier cambio significativo. Te recomendamos revisar esta política regularmente.</div>
                </div>
                <div>
                    <h2>10. Contacto</h2>
                    <div>Para preguntas, solicitudes o aclaraciones sobre esta política, por favor contacta a nuestro equipo de privacidad en {EMAIL}.</div>
                </div>
            </div>
            <div className='mt-10' />
            <Footer />
        </div>
    );
}

export default Privacy;
