import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './groupjoin.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { APP_NAME, URL_ANDROID, URL_IOS } from '../..';

function GroupJoin() {

    const { id } = useParams();
    const [url, setUrl] = useState("");
    const [urlLink, setUrlLink] = useState('');

    useEffect(() => {
        const fallbackLink = `https://plannits.es/group/${id}/join`;
        let url = `app://${APP_NAME.toLowerCase()}/group/${id}/join`;
        setUrl(url);
        openApp(url, fallbackLink);
    }, [id]);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();

        if (/android/.test(userAgent)) {
            setUrlLink(URL_ANDROID);
        } else if (/iphone|ipod/.test(userAgent)) {
            setUrlLink(URL_IOS);
        } else {
            setUrlLink(URL_ANDROID);
        }
    }, []);

    const openApp = (deepLink, fallbackLink) => {
        window.location.href = deepLink;
  
        setTimeout(() => {
          //window.location.href = fallbackLink;
        }, 1500); // 1.5 segundos de espera
      };

    return (
        <div className="container">
            <Header />
            <div className="container-body">
                <div>
                    <p className="label-title bold center">¡Bienvenido a {APP_NAME}!</p>
                </div>
                <div className='center'>
                    <p className="label-download-app">Comience descargando la aplicación para realizar actividades inolvidables</p>
                </div>
                <div className="pointer center mt-20">
                    <a href={urlLink} className='link' target='_blank' rel="noreferrer">
                        <p className="container-button-download">Descargar aplicación</p>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default GroupJoin;
