import Header from '../header/Header';
import Footer from '../footer/Footer';
import { EMAIL_INFO } from '../..';

function Support() {

    return (
        <div className='container'>
            <Header />
            <div className="container-body">
                <div className="mt-20 center">
                    <span className='bold'>Envíanos un email a la siguiente dirección y responderemos lo antes posible:</span>
                </div>
                <div className="center mt-20">
                    <span className='color-red'>{EMAIL_INFO}</span>
                </div>
                <div className="center mt-20">
                    <span className='bold'>Estamos aquí para asegurarnos de que organizar tus planes sea siempre una experiencia perfecta.</span>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Support;
