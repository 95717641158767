import googlePlay from '../../assets/google_play.png';
import appleStore from '../../assets/apple.png';
import './buttons.css';
import { URL_ANDROID, URL_IOS } from '../..';

function Buttons() {
    return (
        <div className='container-row-2 center'>
            <a href={URL_ANDROID} target='_blank' rel="noreferrer">
                <img src={googlePlay} alt="googlePlay" className='container-google-apple-image' />
            </a>
            <a href={URL_IOS} target='_blank' rel="noreferrer">
                <img src={appleStore} alt="appleStore" className='container-google-apple-image' />
            </a>
        </div>
    );
}

export default Buttons;
