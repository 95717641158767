import Header from '../header/Header';
import Footer from '../footer/Footer';
import { APP_NAME, EMAIL } from '../..';

function TermsUse() {

    return (
        <div className='container'>
            <Header />
            <div className='pl-10 pr-10'>
                <h1>Términos de Uso</h1>
                <div>
                    <h2>1. Información General</h2>
                    <div>Estos Términos de Uso regulan el uso de la aplicación móvil <span className='bold'>{APP_NAME}.</span> Al acceder, instalar o utilizar la Aplicación, aceptas estar sujeto a estos Términos de Uso. Si no estás de acuerdo con ellos, no debes utilizar la Aplicación.</div>
                </div>
                <div>
                    <h2>2. Descripción del Servicio</h2>
                    <div>La aplicación permite a los usuarios realizar las siguientes acciones:</div>
                    <ol>
                        <li>
                            <div><span className='bold pr-5'>Proponer y gestionar eventos:</span>Crear y compartir planes con detalles como fecha, ubicación y descripción en los grupos creados en la aplicación.</div>
                        </li>
                        <li>
                            <div><span className='bold pr-5'>Chat en tiempo real:</span>Comunicarse con otros usuarios a través de mensajes dentro de los grupos.</div>
                        </li>
                        <li>
                            <div><span className='bold pr-5'>Registro y cálculo de gastos compartidos:</span>Registrar gastos y calcular saldos entre usuarios en cada grupo.</div>
                        </li>
                        <li>
                            <div><span className='bold pr-5'>Galería de multimedia:</span>Almacenar y compartir fotos y videos con otros usuarios del grupo.</div>
                        </li>
                    </ol>
                </div>
                <div>
                    <h2>3. Registro y Cuenta de Usuario</h2>
                    <div>Para utilizar la Aplicación, debes registrarte proporcionando información precisa y completa. Eres responsable de mantener la seguridad y confidencialidad de tu cuenta, así como de todas las actividades realizadas a través de ella.</div>
                </div>
                <div>
                    <h2>4. Responsabilidad del Usuario</h2>
                    <div>Debes tener 14 años de edad o más para usar este servicio.</div>
                    <div>Como usuario, te comprometes a:</div>
                    <ul>
                        <li>
                            <div>Usar la Aplicación conforme a la ley y estos Términos de Uso.</div>
                        </li>
                        <li>
                            <div>No compartir contenido ilegal, ofensivo, o que infrinja derechos de terceros.</div>
                        </li>
                        <li>
                            <div>No utilizar la Aplicación para actividades fraudulentas, ofensivas o que vulneren la seguridad de otros usuarios.</div>
                        </li>
                        <li>
                            <div>Mantener actualizada la información de tu cuenta y no compartir tu acceso con terceros.</div>
                        </li>
                    </ul>
                    <div>El incumplimiento de cualquiera de estas obligaciones podrá resultar en la suspensión o cancelación de tu cuenta.</div>
                </div>
                <div>
                    <h2>5. Privacidad y Protección de Datos</h2>
                    <div>El uso de la Aplicación implica el tratamiento de tus datos personales, conforme a nuestra Política de Privacidad. En ella encontrarás información detallada sobre qué datos recopilamos, cómo los utilizamos y tus derechos en relación a ellos.</div>
                </div>
                <div>
                    <h2>6. Limitación de Responsabilidad</h2>
                    <div><span className='bold'>{APP_NAME}</span> no garantiza la disponibilidad interrumpida de la Aplicación ni la ausencia de errores en su funcionamiento. En la medida permitida por la ley, no asumimos responsabilidad alguna por:</div>
                    <ul>
                        <li>
                            <div>Cualquier daño indirecto o pérdida de datos que pueda surgir del uso o incapacidad de usar la Aplicación.</div>
                        </li>
                        <li>
                            <div>Incidentes causados por contenido proporcionado por los usuarios.</div>
                        </li>
                        <li>
                            <div>La pérdida de datos causada por errores técnicos.</div>
                        </li>
                    </ul>
                    <div><span className='bold pr-5'>{APP_NAME}</span>
                        no es de ninguna manera responsable por la precisión o precisión de los datos ingresados por ti o tus amigos en {APP_NAME} (gastos así como los nombres de los participantes). {APP_NAME} no actuará como árbitro dentro de tu grupo y no puede ser responsabilizado por la inexactitud de ciertos datos. {APP_NAME} debe ser visto como una herramienta de consenso entre amigos y no como una herramienta para almacenar el historial de gastos. Bajo ninguna circunstancia puede {APP_NAME} ser responsabilizado de ninguna manera por daños directos o indirectos resultantes de: uso indebido de sus servicios, uso de sus servicios considerado ilegal o fraudulento, inexactitud de datos en una cuenta compartida, resultados erróneos proporcionados por sus servicios, indisponibilidad temporal o permanente de sus servicios debido a una falla de sus servicios, cualquier causa relacionada con sus servicios.
                    </div>
                </div>
                <div>
                    <h2>7. Modificaciones</h2>
                    <div>Nos reservamos el derecho a modificar estos Términos y Condiciones en cualquier momento. Las modificaciones serán efectivas desde su publicación en la Aplicación. Te notificaremos de cualquier cambio significativo, y si continúas usando la Aplicación tras la modificación, se considerará que aceptas los nuevos Términos.</div>
                </div>
                <div>
                    <h2>8. Suspensión y Terminación</h2>
                    <div>Podemos suspender o cancelar tu acceso a la Aplicación si infringes estos Términos de Uso o la normativa vigente. La cancelación de la cuenta no exime de responsabilidades anteriores y no implica la devolución de pagos realizados.</div>
                </div>
                <div>
                    <h2>9. Ley Aplicable y Jurisdicción</h2>
                    <div>Estos Términos de Uso están regidos por las leyes de España. Cualquier disputa se someterá a la jurisdicción exclusiva de los tribunales de Madrid.</div>
                </div>
                <div>
                    <h2>10. Contacto</h2>
                    <div>Para consultas, sugerencias o reclamaciones, puedes ponerte en contacto con nosotros en: <span className='bold'>{EMAIL}</span></div>
                </div>
                <div className='mt-10' />
            </div>
            <Footer />
        </div>
    );
}

export default TermsUse;
